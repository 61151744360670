import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthContext } from "../../providers/AuthProvider";
import TokenInput from "./components/TokenInput";
import starBackground from "../../assets/Frame 427320198.svg";
import icon1 from "../../assets/Captura de Tela 2024-06-18 às 19.42.20 1.png";
import icon2 from "../../assets/iconLogin1.png";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import iconHeart from "../../assets/Vector (23).png";

import "./Login.css";
import api from "../../utils/api";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenUrl = location.state?.scree;
  const { signIn } = useContext(AuthContext);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loadingSms, setLoadingSms] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentScreen, setCurrentScreen] = useState(() => {
    const currentcreenSecion = sessionStorage.getItem("currentScreenLogin");
    return currentcreenSecion !== null
      ? currentcreenSecion
      : screenUrl
      ? screenUrl
      : "screen1";
  });
  const [isAccepted, setIsAccepted] = useState(() => {
    return sessionStorage.getItem("acceptedTerms") === "true"; // Converte a string para booleano
  });
  const [isPhone, setIsPhone] = useState(() => {
    return sessionStorage.getItem("phoneLogin") === "true"; // Converte a string para booleano
  });

  const [token, setToken] = useState(Array(6).fill(""));
  const [checkToken, setCheckToken] = useState(false);
  const [timer, setTimer] = useState(30); // Timer inicial de 30 segundos
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Desabilita o botão inicialmente


  useEffect(() => {
    if (currentScreen !== 'screen3') {
      return; // Se a condição não for atendida, o cronômetro não começa
    }

    if (timer === 0) {
      setIsButtonDisabled(false); // Libera o botão quando o cronômetro chega a 0
      return;
    }

    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Diminui o tempo a cada segundo
      }, 1000);

      return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado ou o timer acabar
    }
  }, [timer, currentScreen]);

  const handleSubmit = async () => {
    const emailLogin = sessionStorage.getItem("emailLogin");
    setLoadingSms(true);
    try {
      const { data } = await api.post(`/talents/request_password`, {
        email: emailLogin,
        phone: true,
      });

      const phoneNumber = data.phone.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
      const last4Digits = phoneNumber.slice(-4); // Pega os últimos 4 dígitos
      
      toast.success(`Nova senha enviada para o telefone com final ${last4Digits}`);
      setTimer(30);
      setIsButtonDisabled(false);
    } catch (error) {
      toast.error(error.response.data.error);
      console.log(error);
    } finally {
      setLoadingSms(false);
    }
  };

  const handleSubmitEmail = async () => {
    const emailLogin = sessionStorage.getItem("emailLogin");
    setLoadingSms(true);
    try {
   await api.post(`/talents/request_password`, {
        email: emailLogin,
      });
      toast.success("Nova senha enviada por E-mail");
      setTimer(30);
      setIsButtonDisabled(false);
    } catch (error) {
      toast.error(error.response.data.error);
      console.log(error);
    } finally {
      setLoadingSms(false);
    }
  };

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email } = values;
    setLoadingToken(true);
    try {
      const { data } = await api.post(`/talents/request_password`, {
        email: email,
      });
      
      setLoadingToken(false);
      toast.success("Nova senha enviada para o seu email");
      setIsAccepted(data?.accepted_terms);
      setIsPhone(data?.phone ? true : false)
      sessionStorage.setItem("acceptedTerms", `${data?.accepted_terms}`);
      sessionStorage.setItem("phoneLogin", `${data?.phone ? 'true' : 'false'}`);
      sessionStorage.setItem("emailLogin", email);
      sessionStorage.setItem("currentScreenLogin", "screen3");
      setCurrentScreen("screen3");
    } catch (error) {
      toast.error("e-Mail não cadastrado. Tente outro 😉");
    } finally {
      setLoadingToken(false);
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const handleLogin = async () => {
    const emailLogin = sessionStorage.getItem("emailLogin");

    if (checkAllStringsFilled(token)) {
      try {
        setLoading(true);
        await signIn({
          email: emailLogin || formLogin.values.email,
          password: joinStrings(token),
        });
        setLoading(false);
        sessionStorage.removeItem("acceptedTerms");
        sessionStorage.removeItem("phoneLogin");
        sessionStorage.removeItem("currentScreenLogin");
        sessionStorage.removeItem("emailLogin");
      } catch (error) {
        console.log(error);

        toast.error("Senha inválida");
      } finally {
        setLoading(false);
      }
    } else {
      setCheckToken(true);
    }
  };

  const checkAllStringsFilled = (strings) => {
    return strings.every((str) => str.trim() !== "");
  };

  function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return (
          <div className="contentContainer2">
            <div className="title" style={{ width: "100%", marginTop: "20px" }}>
              Olá, entre por aqui!
            </div>

            <div className="containerInput">
              <div className="text">Preencha com o seu email</div>
              <input
                type="email"
                label="E-mail"
                className="inputLogin"
                onChange={formLogin.handleChange}
                onBlur={formLogin.handleBlur}
                value={formLogin.values.email}
                name="email"
              />
              {formLogin.touched.email && formLogin.errors.email && (
                <div style={{ color: "red" }} className="text">
                  {formLogin.errors.email}
                </div>
              )}
            </div>

            <div className="contianerButtonsLogin">
              <div
                className="buttonLogin style1"
                onClick={formLogin.handleSubmit}
              >
                {loadingToken ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Enviar token de acesso"
                )}
              </div>
            </div>
          </div>
        );
      case "screen3":
        return (
          <div className="contentContainer2">
            {/* <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            /> */}
            <div
              className="title"
              style={{
                marginTop: "130px",
                width: "85%",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Acesse seu email e preencha com o token que foi enviado!
            </div>

            <div className="containerInput" style={{ height: "" }}>
              <div className="text">Preencha com o token</div>
              <TokenInput token={token} setToken={setToken} />
              <div style={{ color: "red" }} className="text">
                {checkToken && "token é obrigatório"}
              </div>
            </div>

            {!isAccepted && (
              <div className="termosBox">
                <p>
                  Ao clicar em "Entrar", você concorda com os
                  <a
                    href="http://ecomlovers.com/termos-de-uso"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    termos de uso{" "}
                  </a>{" "}
                  e
                  <a
                    href="http://ecomlovers.com/privacidade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    política de privacidade{" "}
                  </a>
                  .
                </p>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Exibe o tempo restante do cronômetro */}
              <div className="timer" style={{ width: '250px', marginTop: isMobileDevice() && '20px'}}>
                  <span>Não recebeu o token?</span>
                </div>
              {timer !== 0 ? (
                <div className="timer" style={{ width: '250px', marginTop: '10px' }}>
                  <span  style={{ opacity: "50%" }}>{`Você poderá solicitar um novo token via ${isPhone ? 'SMS' : 'E-mail'} em ${timer} segundos`}</span>
                </div>
              ) : (
                isPhone ? (
                  <button
                onClick={handleSubmit}
                disabled={isButtonDisabled}
                className="SMsbUtton"
                style={{ opacity: timer !== 0 && "50%", marginTop: '10px' }}
              >
                {loadingSms ? (
                  <span className="loading">Enviando...</span>
                ) : (
                  "Enviar Token por SMS"
                )}
              </button>
                ) : (
                  <button
                onClick={handleSubmitEmail}
                disabled={isButtonDisabled}
                className="SMsbUtton"
                style={{ opacity: timer !== 0 && "50%", marginTop: '10px' }}
              >
                {loadingSms ? (
                  <span className="loading">Enviando...</span>
                ) : (
                  "Enviar Token por E-mail"
                )}
              </button>
                )
              )}

              {/* Botão de envio que é desabilitado até o tempo acabar */}
             
            </div>

            <div className="contianerButtonsLogin">
              <button className={`buttonLogin style1`} onClick={handleLogin}>
                {loading ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Entrar"
                )}
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="contentContainer">
            <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            />
            <div className="title">Bem-Vindo a eComLovers!</div>
            <div className="subtitle">
              Pronto para decolar em uma jornada incrível no mundo do Ecommerce?
            </div>

            <div
              className="contianerButtonsLogin"
              style={{ marginTop: "10px", marginBottom: "50px" }}
            >
              <div
                className="buttonLogin style1"
                onClick={() => {
                  navigate("/cadastro");
                }}
              >
                Vamos começar agora!
              </div>
              <div
                className="style2"
                onClick={() => setCurrentScreen("screen2")}
                style={{ marginTop: "10px", cursor: "pointer" }}
              >
                Acessar minha conta
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="containerLogin">
      <div className="containerContentALl">
        <div
          style={{
            height: currentScreen === "screen1" ? "200px" : "",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "15px",
            gap: "5px",
          }}
          onClick={() => {
            setCurrentScreen("screen1");
            sessionStorage.removeItem("currentScreenLogin");
            setTimer(30);
            setIsButtonDisabled(false);
          }}
          className="backGrounDivid"
        >
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => {
              setCurrentScreen("screen1");
              sessionStorage.removeItem("currentScreenLogin");
              setTimer(30);
              setIsButtonDisabled(false);
            }}
          />

          <div className="lineLOgin" />
        </div>
        <div className="contentCONtainerLogin">
          {/* <img
            src={starBackground}
            alt="star background"
            className="imgBackground"
          /> */}
          {/* <img
          src={currentScreen === "screen1" ? icon1 : icon2}
          alt="icon"
          className={currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"}
        /> */}

          {renderScreen(currentScreen)}
        </div>
        <div className="footerLOgin">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import { MainSidebar } from "../../components/MainSidebar/MainSidebar";
import { MainTopbar } from "../MainTopBar/MainTopbar";
import { ContentContainer } from "./ContentContainer";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../providers/AuthProvider";
import mockNotifications from "../../utils/examples/notifications.json";
import { Toolbar } from "@mui/material";
import iconHeart from "../../assets/Vector (23).png";

import "./AuthorizedLayout.css";
import { useLocation } from "react-router-dom";

export const AuthorizedLayout = ({ children, styles }) => {
  const { pathname } = useLocation();
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/talents/notifications");
      // const { data } = mockNotifications;

      if (data) {
        setNotifications(
          data
            ?.map((item) => {
              return {
                notificationId: item?.id,
                ...item?.attributes,
              };
            })
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      }
      return [];
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de notificações.");
    } finally {
      setLoading(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  let width = isMobileDevice() ? 10 : 80;

  return (
    <Box style={{ backgroundColor: "#fff", minHeight: "80vh" }}>
      <div
        style={{
          position: "relative",
          height: "80px",
          width: "100vw",
        }}
      ></div>
      <MainSidebar
        visible={mainSidebarVisible}
        setVisible={setMainSidebarVisible}
        supportModal={supportModal}
        setSupportModal={setSupportModal}
      />

      {/* <Toolbar sx={{ paddingBottom: `${width}px` }} /> */}

      <div className="containerBoxSpace">
        <div className="contentWEb">
          <MainTopbar
            mainSidebarVisible={mainSidebarVisible}
            setMainSidebarVisible={setMainSidebarVisible}
            notificationsVisible={notificationsVisible}
            setNotificationsVisible={setNotificationsVisible}
            loading={loading}
            notifications={notifications}
          />
          {!isMobileDevice() && (
            <div
              style={{ marginBottom: "50px", height: "20px", width: "100%" }}
            ></div>
          )}
          <ContentContainer
            styles={styles}
            notificationsVisible={notificationsVisible}
            setNotificationsVisible={setNotificationsVisible}
            loading={loading}
            notifications={notifications}
            setNotifications={setNotifications}
          >
            {children}
          </ContentContainer>
          {/* <div className={pathname === '/settings' ? 'footerPageMObileProfile' : 'footerPageMObile'}>
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div> */}
        </div>

        <div className="footerPage">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </div>
    </Box>
  );
};
